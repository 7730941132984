import React from "react";
import { Container, Button, Form } from 'react-bootstrap'

const SignIn = () =>{

  return(
    <Container>
      <Form>
        <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter name" />
        </Form.Group>
        <Button variant="primary" type="submit">
          Breathe In
        </Button>
      </Form>
    </Container>
  )
}

export default SignIn;

