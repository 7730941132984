import React from "react";
import "./styles.css";
import { Row, Col } from "react-bootstrap";
import Logo from "../../assets/images/icon-circle.png";
import Log from "../../assets/images/practice.png";
import Snap from "../../assets/images/voiceCam.png";
import AppStoreBadge from "../../assets/images/appStoreBadge.svg";

const Landing = () => {
  return (
    <main>
      <div className="container py-4">
        <header className="pb-3 mb-4 t">
          <a
            href="/"
            className="d-flex align-items-center text-dark text-decoration-none"
          >
            <img
              style={{ width: "40px" }}
              className="me-3"
              src={Logo}
              alt="logo"
            ></img>
            <span className="fs-4 text-white me-2 fancy-font">
              Breathe in Retrospect
            </span>
          </a>
        </header>

        <div className="p-5 mb-4 bg-light-shade rounded-3">
          <div className="container-fluid py-5">
            <h1 className="display-5 fw-bold">
              Track Yoga Certification Hours
            </h1>
            <p className="col-md-8 fs-4">
              Log your yoga hours in the app and easily export to a file when
              needed.
            </p>
            <a
              href="https://apps.apple.com/us/app/breathe-in-retrospect/id1535837327?ign-itscg=30200&ign-itsct=apps_box&platform=iphone"
              className="me-3"
              alt="app-store-badge"
            >
              <img src={AppStoreBadge} alt="app-store-badgenpm "></img>
            </a>
            <a
              className="btn btn-outline-dark btn-lg  my-2"
              type="button"
              target="_blank"
              href="https://forms.gle/2javgLJs7r4919Dh6"
              rel="noreferrer"
            >
              Notify Me When Available on Web
            </a>

            {/* <button className="btn btn-outline-primary btn-lg ml-5" type="button">Get Started</button> */}
          </div>
        </div>

        <div className="row mb-4 align-items-md-stretch">
          <div className="col-md-4 my-2">
            <div className="h-100 p-5 text-white bg-dark-shade rounded-3">
              <h2>3440</h2>
              <p>Hours of Yoga Logged</p>
              {/* <button className="btn btn-outline-light" type="button">Example button</button> */}
            </div>
          </div>
          <div className="col-md-4  my-2">
            <div className="h-100 p-5 text-white bg-dark-shade rounded-3">
              <h2>3,490</h2>
              <p>Downloads from AppStore</p>
              {/* <button className="btn btn-outline-light" type="button">Example button</button> */}
            </div>
          </div>
          <div className="col-md-4  my-2">
            <div className="h-100 p-5 text-white bg-dark-shade rounded-3">
              <h2>13</h2>
              <p>Countries with Active Users</p>
              {/* <button className="btn btn-outline-light" type="button">Example button</button> */}
            </div>
          </div>
        </div>

        <Row className="align-items-md-stretch text-light pt-5 mb-4">
          <Col md={6} sm={12} className="pe-4 text-center mb-5 ">
            <div className="text-start">
              <h2>Hands-Free Camera for Asana Photos</h2>
              <p className="w75">
                No more fidgeting with your phone camera timer trying to get
                that perfect-pose picture. Breathe in Retrospect's Asana Voice
                Cam allows you to take pictures during your yoga practice just
                by saying the word, "snap!"
              </p>
            </div>
            <img alt="voice cam snapshot" className="w-50 mx-auto" src={Snap} />
          </Col>
          <Col md={6} sm={12} className="text-center ">
            <div className="text-start">
              <h2>Log Teacher Training Hours</h2>
              <p>
                Keep track of your teacher training hours with a sense of calm.
                There's no need for cluttered binders full of training logs. Log
                your training in the app and easily export to a .csv file
                whenever necessary.
              </p>
            </div>

            <img alt="voice cam snapshot" className="w-50 mx-auto" src={Log} />
          </Col>
        </Row>

        <footer className="pt-3 mt-4 text-white-50 ">
          <a href="/privacypolicy" className=" link-light">
            Privacy Policy
          </a>
          <div class="vr mx-3" />
          <a
            href="https://cleverpixel.com"
            target="_blank"
            rel="noreferrer"
            className=" link-light"
          >
            Clever Pixel
          </a>
          &copy; 2020
        </footer>
      </div>
    </main>
  );
};

export default Landing;
