import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "../../pages/SignIn";
// import App from '../App/App'
import Landing from "../../pages/Landing";
import Privacy from "../../pages/Privacy";

// const rootElement = document.getElementById("root");

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/breathein" element={<SignIn />} />
      <Route path="/privacypolicy" element={<Privacy />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
